import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './Paginate.module.postcss';

const Paginate = (props) => {
  return (
    <ReactPaginate
      {...props}
      containerClassName={styles.Pagination}
      pageClassName={styles.Pagination_page}
      activeClassName={styles.Pagination_selected}
      nextClassName={styles.Pagination_next}
      previousClassName={styles.Pagination_previous}
      breakClassName={styles.Pagination_break}
      disabledClassName={styles.Pagination_disabled}
    />
  );
};

export default Paginate;
