import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { featuredTruckSchema, featuredTruckListSchema } from './schemas';

// Create Base Actions
const baseActions = baseActionsFor('featured_trucks', 'featuredTruck');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'featured_truck',
  listName: 'featured_trucks',
  apiEndpoint: 'trucks',
  resourceSchema: featuredTruckSchema,
  listSchema: featuredTruckListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {
};

// Merge Actions
const actions = Object.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
