import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DayPicker from 'react-day-picker';
import cn from 'classnames';
import Toggle from 'react-toggle';
import ReactSelect from 'react-select';

import '../../../../stylesheets/shared/components/ReactToggle.postcss';
import '../../../../../node_modules/react-day-picker/lib/style.css';
import dayPickerStyles from '../../../../stylesheets/public/components/DayPicker.module.postcss';
import reactSelectStyles from '../../../../stylesheets/public/components/ReactSelect.postcss';
import styles from './MapFilters.module.postcss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

class MapFilters extends Component {
  constructor(props, _railsContext) {
    super(props);
    dayjs.tz.setDefault('America/Los_Angeles');
    this.onClickOut = this.onClickOut.bind(this);
    this.updateDayFilter = this.updateDayFilter.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.toggleSelectingDay = this.toggleSelectingDay.bind(this);
    this.onFoodCategoryChange = this.onFoodCategoryChange.bind(this);
    this.selectedDays = this.selectedDays.bind(this);
    this.setDayToday = this.setDayToday.bind(this);
    this.setDayTomorrow = this.setDayTomorrow.bind(this);
    this.onChangeDietaryPrefs = this.onChangeDietaryPrefs.bind(this);
    this.onChangeCuisine = this.onChangeCuisine.bind(this);
    this.setDietaryPrefs = this.setDietaryPrefs.bind(this);

    this.dietaryOptions = [
      { value: 'vegetarian', label: 'Vegetarian' },
      { value: 'vegan', label: 'Vegan' },
      { value: 'gluten_free', label: 'Gluten-free' },
      { value: 'paleo', label: 'Paleo' }
    ]
  }

  onClickOut(e) {
    if (e.target === e.currentTarget) {
      this.props.onClickOut();
    }
  }

  onFoodCategoryChange(selected) {
    let foodId = null;
    if (selected) foodId = selected.id;
    this.props.updateQueryParam({ foodCategory: foodId });
  }

  updateDayFilter(filter) {
    this.setState({
      selectingDay: false
    });

    this.props.updateQueryParam({
      day: filter === this.props.currentFilters.day ? null : filter,
    });
  }

  handleDayClick(day, { disabled, selected }) {
    if (disabled) return;

    this.props.toggleSelectingDay(false);

    const formattedDate = dayjs(day).format('YYYY-MM-DD');

    this.props.updateQueryParam({
      day: selected ? null : formattedDate
    });
  }

  onChangeDietaryPrefs(selectedOptions) {
    const filters = { vegetarian: false, vegan: false, gluten_free: false, paleo: false };
    selectedOptions.forEach((option) => {
      filters[option.value] = true;
    });

    this.props.updateQueryParam(filters);
  }

  setDietaryPrefs() {
    const { currentFilters } = this.props;

    let selectedPrefs = [];
    this.dietaryOptions.forEach(option => {
      if (currentFilters[option.value] === 'true') {
        selectedPrefs.push(option);
      }
    });

    return selectedPrefs;
  }

  onChangeCuisine(selectedOptions) {
    let selectedCuisines = [];
    selectedOptions.forEach(option => {
      selectedCuisines.push(option.id);
    });

    this.props.updateQueryParam({ food_categories: selectedCuisines });
  }

  setCuisine() {
    const { currentFilters, foodCategories } = this.props;

    let selectedCuisines = [];
    if (Array.isArray(currentFilters.food_categories)) {
      currentFilters.food_categories.forEach(option => {
        let cuisine = foodCategories.find((category) => {
          return category.id === option;
        });
        selectedCuisines.push(cuisine);
      });
    } else {
      if (currentFilters.food_categories) {
        selectedCuisines = foodCategories.find((category) => {
          return category.id === currentFilters.food_categories;
        });
      }
    }

    return selectedCuisines;
  }

  toggleSelectingDay() {
    this.props.toggleSelectingDay();
  }

  disabledDays(day) {
    return day < dayjs().startOf('day');
  }

  selectedDays(day) {
    const currentDayFilter = this.props.currentFilters.day;

    if (currentDayFilter === 'today') {
      const today = dayjs()
        .hour(12)
        .minute(0)
        .second(0)
        .millisecond(0);

      return day.getTime() === today.toDate().getTime();
    }

    if (currentDayFilter === 'tomorrow') {
      const tomorrow = dayjs()
        .hour(12)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(1, 'day');

      return day.getTime() === tomorrow.toDate().getTime();
    }

    const selectedDay = dayjs(currentDayFilter, 'YYYY-MM-DD')
      .hour(12)
      .minute(0)
      .second(0)
      .millisecond(0);

    return day.getTime() === selectedDay.toDate().getTime();
  }

  setDayToday() {
    this.updateDayFilter('today');
  }

  setDayTomorrow() {
    this.updateDayFilter('tomorrow');
  }

  render() {
    const { currentFilters, currentMode, selectingDay, toggleCurrentMode, foodCategories } = this.props;

    let selectedOtherDay = false;
    if (currentFilters.day &&
        currentFilters.day !== 'today' &&
        currentFilters.day !== 'tomorrow') {
      selectedOtherDay = true;
    }

    let initialMonth = dayjs().toDate();
    if (currentFilters.day &&
        currentFilters.day !== 'today' &&
        currentFilters.day !== 'tomorrow') {
      initialMonth = dayjs(currentFilters.day, 'YYYY-MM-DD').toDate();
    }

    return (
      <div
        className={cn(styles.MapFilters, {
          [styles.MapFilters__open]: this.props.visible,
        })}
        onClick={this.onClickOut}
      >
        <div className={styles.MapFilters_primary}>
          <div className={styles.MapFilters_section}>
            <button
              className={cn({
                [styles.MapFilters_button]: true,
                [styles.MapFilters_button__on]: currentFilters.day === 'today',
              })}
              onClick={this.setDayToday}
            >
              Today
            </button>
            <button
              className={cn({
                [styles.MapFilters_button]: true,
                [styles.MapFilters_button__on]: currentFilters.day === 'tomorrow',
              })}
              onClick={this.setDayTomorrow}
            >
              Tomorrow
            </button>
            <div className={styles.MapFilters_selectDay}>
              <button
                className={cn({
                  [styles.MapFilters_button]: true,
                  [styles.MapFilters_button__active]: selectingDay,
                  [styles.MapFilters_button__selected]: selectedOtherDay
                })}
                onClick={this.toggleSelectingDay}
              >
                Select Date
              </button>
              {selectingDay &&
                <div className={styles.MapFilters_calendar}>
                  <DayPicker
                    disabledDays={this.disabledDays}
                    onDayClick={this.handleDayClick}
                    selectedDays={this.selectedDays}
                    initialMonth={initialMonth}
                  />
                </div>
              }
            </div>
          </div>

          <div className={styles.MapFilters_section}>
            <ReactSelect
              placeholder={'Dietary Preferences'}
              multi={true}
              options={this.dietaryOptions}
              searchable={false}
              clearable={false}
              value={this.setDietaryPrefs()}
              onChange={this.onChangeDietaryPrefs}
            />

            <ReactSelect
              placeholder={'Cuisine'}
              multi={true}
              options={foodCategories}
              valueKey={'id'}
              clearable={false}
              labelKey={'name'}
              searchable={true}
              value={this.setCuisine()}
              onChange={this.onChangeCuisine}
            />
          </div>

          <div className={styles.MapFilters_section + " " + styles.MapFilters_section__map}>
            {!this.props.mapDisabled && (
              <React.Fragment>
                <label>Show Map</label>
                <Toggle
                  defaultChecked={currentMode === 'map'}
                  icons={false}
                  onChange={toggleCurrentMode}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MapFilters.propTypes = {
  currentFilters: PropTypes.object,
  currentMode: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  mapDisabled: PropTypes.bool,
  onClickOut: PropTypes.func,
  selectingDay: PropTypes.bool,
  toggleCurrentMode: PropTypes.func,
  toggleSelectingDay: PropTypes.func,
  updateQueryParam: PropTypes.func,
  visible: PropTypes.bool,
};

export default MapFilters;
