import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { lazyload } from 'react-lazyload';
import dayjs from 'dayjs';

import IsoMorphUtils from '../../modules/isoMorphUtils';

import styles from './TruckTease.module.postcss';
import '../../../stylesheets/shared/components/Tippy.postcss';

@lazyload({
  once: true,
  offset: 200
})
class TruckTease extends PureComponent {
  renderEventDetails() {
    const { truck } = this.props;

    if (!truck.event) { return; }

    const address = truck.location.address.split(', ').slice(0, -1).join(', ');
    const start = dayjs(truck.event.start_time).format('h:mma');
    const end = dayjs(truck.event.end_time).format('h:mma');
    const location = truck.location.name ? truck.location.name : address;

    return (
      <div className={styles.TruckTease_event} key={`${truck.id}-event-tease`}>
        <span>
          <Tippy
            content={<a href={`https://maps.google.com?q=${address}`} target="_blank">{address}</a>}
            interactive
            duration={0}
          >
            <span>
              <Link to={`/schedule/${truck.location.slug}`} target="_blank" rel="noopener noreferrer">
                <span>
                  <img src="/static/images/pin.svg" alt="Map Marker" width='12' height='15' />
                  {location}
                </span>
              </Link>
            </span>
          </Tippy>
        </span>
        <Link to={`/schedule/${truck.location.slug}`} target="_blank" rel="noopener noreferrer">
          <span>
            <img src="/static/images/clock.svg" width='12' height='12' />
            {start} <span className={styles.arrow}>&rarr;</span> {end}
          </span>
        </Link>
      </div>
    );
  }

  render() {
    const { truck } = this.props;

    let categories = truck.food_categories_string;
    let image = null;

    if (truck.featured_photo) {
      image = `${IsoMorphUtils.railsContext.imageBase}/${truck.featured_photo}`;
    } else {
      image = '/static/images/truck-placeholder.png';
    }

    return (
      <div className={styles.TruckTease}>
        <Link className="u-block" to={`/food-trucks/${truck.id}`} target="_blank" rel="noopener noreferrer">
          {image &&
            <div
              className={styles.TruckTease_image}
              style={{
                backgroundImage: `url("${image}")`
              }}
            />
          }

          <div className={styles.TruckTease_info}>
            {/* TODO {this.renderEventDetails()} */}
            <div className={styles.TruckTease_name}>{truck.name}</div>
            <div className={styles.TruckTease_category}>{categories}</div>
            <div>TODO Food Truck - Food Truck</div>
          </div>
        </Link>
      </div>
    );
  }
}

TruckTease.propTypes = {
  truck: PropTypes.object
};

TruckTease.defaultProps = {
  truck: {
    food_categories: [{ name: 'Food Category' }],
    name: 'Truck Name',
    id: '#'
  }
};

export default TruckTease;
