import API from '../modules/api';
import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { locationSchema, locationListSchema } from './schemas';
import { displayError } from './errorActions';

// Create Base Actions
const baseActions = baseActionsFor('locations', 'location');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'location',
  listName: 'locations',
  resourceSchema: locationSchema,
  listSchema: locationListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {
  addSubscriber(id, email) {
    return dispatch => {
      dispatch(baseActions.updateStart());

      const options = { id, email }
      const promise = API.post(`/api/locations/${options.id}/add_subscriber`, options);

      promise.then((response) => {
        dispatch(baseActions.updateSuccess(response));
      });

      promise.catch((response) => {
        dispatch(baseActions.updateError(response));
        if (response.status === 404) {
          dispatch(displayError(404, response.statusText, true));
        } else {
          let message = response.statusText;
          if (response.responseJSON && response.responseJSON.error) {
            message = response.responseJSON.error;
          }
          dispatch(displayError(500, message, true));
        }
      });

      return promise;
    };
  },
};

// Merge Actions
const actions = Object.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
