import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// HOC
import paginated from '../../hoc/paginated';

// Components
import TrucksGrid from './TrucksGrid';
import PureLoader from '../shared/PureLoader';

// Actions
import truckActions from '../../actions/truckActions';
import { clearServerRenderedPath } from '../../actions/uiActions';

// Selectors
import { getAllTrucks, getFoodCategory } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';

class Trucks extends Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      ready: false || IsoMorphUtils.ready(props)
    };
  }

  componentDidMount() {
    const { currentFilters, selectedPage } = this.props;
    this.fetchTrucks(selectedPage, currentFilters);
    this.setState({ ready: true });
  }

  componentWillUpdate(nextProps, nextState) {
    this.fetchTrucksIfWillUpdate(nextProps);
  }

  fetchTrucksIfWillUpdate(nextProps) {
    const currentPage = this.props.selectedPage;
    const nextPage = nextProps.selectedPage;
    const currentFilters = this.props.currentFilters;
    const nextFilters = nextProps.currentFilters;

    if (currentPage !== nextPage) {
      this.fetchTrucks(nextPage, nextFilters);
    }

    if (currentFilters !== nextFilters) {
      this.fetchTrucks(nextPage, nextFilters);
    }
  }

  fetchTrucks(page, filters) {
    const { vegetarian, vegan, gluten_free, paleo, query, food_categories } = filters;
    let options = { page };

    options.page_size = 21;
    if (vegetarian) options.vegetarian = true;
    if (vegan) options.vegan = true;
    if (gluten_free) options.gluten_free = true;
    if (paleo) options.paleo = true;
    if (query) options.search = query;
    if (food_categories) options.food_categories = food_categories;

    this.props.fetchTrucks(options);
  }

  render() {
    if (!this.state.ready) return null;

    const { trucks, food_categories, pagination, getPagination, selectedPage, loading, cityConfig } = this.props;

    return (
      <PureLoader entities="truck" actions="fetch" loading={loading} padded center>
        <div>
          {trucks.length > 0 && (
            <TrucksGrid
              trucks={trucks}
              cityConfig={cityConfig}
            />
          )}

          {trucks.length === 0 && (
            <div className="u-textCenter u-mt3 u-mb2">
              <h3>No {cityConfig.vehicle_type.toLowerCase()}s found matching the current filters</h3>
              <p>Please modify or remove the current filters</p>
            </div>
          )}

          {pagination &&
            <div>{getPagination()}</div>
          }

          {/* <div className="Advertisement u-mt2 u-mb0">
            <Ad slot="7735243397" />
          </div> */}
        </div>
      </PureLoader>
    );
  }
}

Trucks.propTypes = {
  clearServerRenderedPath: PropTypes.func,
  clearTrucks: PropTypes.func,
  currentFilters: PropTypes.object,
  fetchTrucks: PropTypes.func,
  foodCategory: PropTypes.object,
  getPagination: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  pagination: PropTypes.object,
  resetSelectedPage: PropTypes.func,
  selectedPage: PropTypes.number,
  trucks: PropTypes.array,
  ui: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    trucks: getAllTrucks(state, props),
    foodCategory: getFoodCategory(state, props),
    loading: state.ui.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearTrucks() {
      return dispatch(truckActions.clear());
    },
    fetchTrucks(options) {
      dispatch(truckActions.fetch(options));
    },
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

const paginationConfig = {
  entity: 'truck'
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Trucks);
const paginatedComponent = paginated(connectedComponent, paginationConfig);
export default paginatedComponent;
