import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import Paginate from '../components/shared/Paginate';

// Selectors
import { getPaginationForEntity } from '../selectors';

// Actions

function paginated(WrappedComponent, config) {
  class Paginated extends Component {
    constructor(props) {
      super(props);

      this.getPagination = this.getPagination.bind(this);
      this.onPageChange = this.onPageChange.bind(this);
      this.resetSelectedPage = this.resetSelectedPage.bind(this);

      this.defaultParams = {
        page: 1
      };

      let params = {};

      if (props.location) {
        const queryParams = this.getQueryParams(props);
        params = Object.assign({}, this.defaultParams, queryParams);
        params.page = Number(params.page);
      }

      this.state = params;
    }

    componentWillUpdate(nextProps, nextState) {
      this.handleParamsChange(nextProps);
    }

    onPageChange(pageInfo) {
      const page = pageInfo.selected + 1;
      if (page === this.state.page) return;
      const previousParams = this.getQueryParams(this.props);
      const params = Object.assign({}, previousParams, { page });

      this.setState({ page });

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: queryString.stringify(params)
      });
    }

    getQueryParams(props) {
      return queryString.parse(props.location.search);
    }

    getPagination() {
      const { pagination } = this.props;

      if (pagination.total_pages < 2) {
        return null;
      }

      return (
        <Paginate
          pageCount={pagination.total_pages}
          onPageChange={this.onPageChange}
          forcePage={this.state.page - 1}
          initialPage={this.state.page - 1}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
        />
      );
    }

    handleParamsChange(nextProps) {
      if (!this.props.routing || !this.props.routing.location || !nextProps.routing.location) {
        return;
      }

      const previousParams = this.getQueryParams(this.props);
      const nextParams = this.getQueryParams(nextProps);

      if (JSON.stringify(previousParams) === JSON.stringify(nextParams)) {
        return;
      }

      const mergedParams = Object.assign({}, this.defaultParams, nextParams);
      mergedParams.page = Number(mergedParams.page);

      this.setState(mergedParams, () => {});
    }

    resetSelectedPage() {
      this.setState({
        page: 1
      });
    }

    render() {
      return (
        <WrappedComponent
          getPagination={this.getPagination}
          selectedPage={this.state.page}
          resetSelectedPage={this.resetSelectedPage}
          {...this.props}
        />
      );
    }
  }

  Paginated.propTypes = {
    routing: PropTypes.object,
    pagination: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
  };

  function mapStateToProps(state, props) {
    return {
      routing: state.routing,
      pagination: getPaginationForEntity(state, config.entity)
    };
  }

  function mapDispatchToProps(dispatch) {
    return {};
  }

  return connect(mapStateToProps, mapDispatchToProps)(Paginated);
}

export default paginated;
