import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { titleCase } from '../../../modules/helpers';

import styles from './FilterList.module.postcss';

dayjs.extend(utc);
dayjs.extend(timezone);

const FilterItem = ({ name, value, arrayName, foodCategories, removeFilter }) => {

  const formatFilter = (name, arrayName) => {
    const withSpaces = name.replace('_', ' ');
    const capitalized = titleCase(withSpaces);

    if (arrayName === "food_categories" && foodCategories && foodCategories.length > 0) {
      const formattedName = foodCategories.find((fc) => { return fc.id === name });
      return formattedName ? formattedName.name : capitalized;
    } else {
      return capitalized;
    }
  };

  const formatValue = (name, value) => {
    if (value === 'true') {
      return name;
    } else if (name === 'day') {
      let date = null;
      if (value === 'today' || value === 'tomorrow') {
        return "Open " + value;
      } else {
        date = dayjs(value).tz('America/Los_Angeles');
      }
      return "Open " + date.format('MMMM D, YYYY');
    } else {
      return value;
    }
  }

  const onRemoveFilter = () => {
    if (arrayName) {
      removeFilter(arrayName, name)
    } else {
      removeFilter(name)
    }
  }

  return (
    <div className={styles.FilterList_item}>
      {formatFilter(formatValue(name, value), arrayName)}
      <span onClick={onRemoveFilter}>×</span>
    </div>
  );
};

class FilterList extends PureComponent {

  render() {
    const { filters, foodCategories, removeFilter, removeArrayFilter } = this.props;
    let filtersList = [];

    Object.keys(filters || {}).map((key) => {
      const value = filters[key];
      if (Array.isArray(value)) {
        value.forEach((val) => {
          filtersList.push(
            <FilterItem
              name={val}
              arrayName={key}
              value={'true'}
              foodCategories={foodCategories}
              removeFilter={removeArrayFilter}
              key={key + val}
            />
          );
        })
      } else {
        filtersList.push(
          <FilterItem
            name={key}
            value={value}
            removeFilter={removeFilter}
            key={key + value}
          />
        );
      }
    });

    return (
      <div className={styles.FilterList_wrapper}>
        <h4>All Results</h4>
        <div className={styles.FilterList}>
          {filtersList}
        </div>
      </div>
    );
  }
}

FilterList.propTypes = {
  foodCategories: PropTypes.array,
  filters: PropTypes.object,
  removeFilter: PropTypes.func,
};

export default FilterList;
